import React, { useEffect } from "react";
import { Modal } from "flowbite-react";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthContext, UserOrg } from "@/contexts/common/AuthContextProvider";
import { GetOrgId } from "@/utils/common/auth";
import { useAlert } from "@/hooks/useAlert";
import { api } from "@/utils/common/api";
import { UserAuthDeleteResponse } from "@/types/user";
import { ClearAllCache } from "@/utils/common/cache";
import { useTranslation } from "react-i18next";
import { UserRoles } from "@/enums/user";
import Button from "../library/Button";
import { Icon } from "../library/Icon";

type Props = {
  show: boolean;
  setShow: (value: boolean) => void;
};

const UserManagementModal = ({ show, setShow }: Props) => {
  const { getToken, role } = useAuthContext();
  const { user, loginWithRedirect } = useAuth0();
  const { setIsConfirming, setIsSaving, setAlert } = useAlert();
  const { t } = useTranslation("login");
  const { t: ct } = useTranslation("common");

  const [currentOrg, setCurrentOrg] = React.useState<string | null>(null);
  const [orgs, setOrgs] = React.useState<UserOrg[] | null>(null);

  const getCurrentOrg = async () => {
    const token = await getToken();
    const org_id = GetOrgId(token || "");

    setCurrentOrg(org_id);
  };

  useEffect(() => {
    getCurrentOrg();
  }, []);

  useEffect(() => {
    if (user && user.user_orgs) {
      setOrgs(user.user_orgs);
    }
  }, [user]);

  const handleRemove = async (org_id: string, agency_id: string) => {
    setIsSaving(true, t("removingYourAccess"));
    const agencyId = agency_id.includes("onboarding-")
      ? agency_id.replace("onboarding-", "")
      : agency_id;

    const response = await api<
      void,
      UserAuthDeleteResponse | { message: string }
    >(getToken, `/api/user-access/${org_id}/${agencyId}`, "DELETE");

    setIsSaving(false, "");

    if (response) {
      if (Array.isArray(response)) {
        setOrgs(response);
      } else {
        setAlert({
          message: response.message,
          status: "error",
          secondsToClose: 10,
        });
      }
    } else {
      setAlert({
        message: t("failedToRemoveAccess"),
        status: "error",
        secondsToClose: 10,
      });
    }
  };

  return (
    <Modal show={show} onClose={() => setShow(false)}>
      <Modal.Header>
        <span>{t("userManagement")}</span>
        <p className={clsx("text-sm", "text-600")}>
          {t("userManagementDescription")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>{`${t("accountLevel")}: ${t(role)}`}</div>
        <div className={clsx("text-600", "text-xs")}>
          {t(role === UserRoles.Admin ? "adminDescription" : "userDescription")}
        </div>
        {orgs &&
          orgs.length > 1 &&
          orgs.map((o, i) => (
            <div key={i}>
              <hr className="my-4" />
              <div
                className={clsx(
                  "mb-4",
                  "grid",
                  "gap-4",
                  "grid-cols-[1fr_100px_46px]"
                )}
              >
                <div>
                  <div className={clsx("text-xs", "text-600")}>
                    {t("accountName")}
                  </div>
                  {o.display_name}
                </div>
                <div>
                  <Button
                    type="secondary"
                    disabled={currentOrg === o.id}
                    wide
                    onClick={() => {
                      ClearAllCache();
                      loginWithRedirect({
                        authorizationParams: {
                          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
                          redirect_uri: window.location.href,
                          organization: o.id,
                        },
                      });
                    }}
                  >
                    {currentOrg === o.id ? t("current") : t("switch")}
                  </Button>
                </div>
                <div>
                  <Button
                    type="tertiary"
                    wide
                    onClick={async () => {
                      await setIsConfirming(
                        true,
                        "delete",
                        t("removeYourAccess"),
                        async () => await handleRemove(o.id, o.name),
                        async () => {}
                      );
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer className={clsx("block")}>
        <div className={clsx("!ml-0")}>
          <Button type="secondary" onClick={() => setShow(false)}>
            {ct("close")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UserManagementModal;
