import clsx from "clsx";
import React, { useEffect } from "react";
import FancyLoader from "@/components/common/fancyLoader";
import { Pie } from "@/components/common/charts/Pie";
import { TotalCallStat } from "@/types/admin/dashboard";
import { PieDataType } from "@/utils/common/charts";
import { GetColors } from "@/utils/library/color";
import { useTranslation } from "react-i18next";

type StatCompProps = {
  isAdmin: boolean;
  totalCalls: TotalCallStat[] | null | undefined;
};

const StatCompCallsByDirection: React.FC<StatCompProps> = ({
  isAdmin,
  totalCalls,
}) => {
  const { t } = useTranslation("stats");
  const { t: cat } = useTranslation("calls");

  const [pieData, setPieData] = React.useState<PieDataType[]>([]);

  useEffect(() => {
    if (!totalCalls) {
      setPieData([]);
      return;
    }

    const colors = GetColors(2);

    const newPieData = totalCalls.map((stat) => {
      return {
        active: true,
        id: stat.direction,
        label: cat(stat.direction),
        value: stat.count || 0,
        color: stat.direction === "outbound" ? colors[0] : colors[1],
      };
    });

    setPieData(
      newPieData
        .sort((a, b) => b.value - a.value)
        .map((pd) => ({
          ...pd,
          percent:
            (pd.value / newPieData.reduce((acc, pd2) => acc + pd2.value, 0)) *
            100,
        }))
    );
  }, [totalCalls]);

  const recalculatePiePercent = (data: PieDataType[]) => {
    setPieData(
      data.map((pd) => ({
        ...pd,
        percent:
          (pd.value /
            data.reduce((acc, pd2) => acc + (pd2.active ? pd2.value : 0), 0)) *
          100,
      }))
    );
  };

  return (
    <div
      className={clsx(
        "bg-panel",
        "p-4",
        "mt-4",
        "rounded-xl",
        "shadow-lg",
        "border",
        "relative"
      )}
    >
      <h1 className={clsx("mb-2", "text-xl")}>{t("callsByDirection")}</h1>
      <hr />
      {totalCalls === null && (
        <div className="mt-2">{t("errorLoadingStats")}</div>
      )}
      {totalCalls !== null && (
        <>
          <div
            className={clsx("mt-2", "grid", "gap-4", "grid-cols-1", {
              "lg:grid-cols-[2fr_1fr]": isAdmin,
            })}
          >
            <div
              className={clsx("h-80", "flex", "justify-center", "items-center")}
            >
              {totalCalls === undefined && (
                <div>
                  <FancyLoader />
                </div>
              )}
              {totalCalls && (
                <Pie
                  data={pieData.filter((pd) => pd.active)}
                  arcLabel={(e: { id: string }) => {
                    const pd = pieData.find((pd) => pd.id === e.id);

                    if (!pd) {
                      return "";
                    }

                    return `${(pd.percent || 0).toFixed(2)}%`;
                  }}
                />
              )}
            </div>
            <div
              className={clsx({
                "grid grid-cols-1 md:grid-cols-2": !isAdmin,
              })}
            >
              {pieData.map((pd) => (
                <div
                  key={pd.id}
                  className={clsx(
                    "mt-2",
                    "grid",
                    "gap-2",
                    "grid-cols-[30px_1fr]",
                    "cursor-pointer",
                    "leading-4"
                  )}
                  onClick={() => {
                    const newData = pieData.map((pd2) => {
                      if (pd2.id === pd.id) {
                        return {
                          ...pd2,
                          active: !pd2.active,
                        };
                      }

                      return pd2;
                    });

                    recalculatePiePercent(newData);
                  }}
                >
                  <div
                    style={{
                      backgroundColor: pd.color,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    className={clsx("text-sm", {
                      "line-through": !pd.active,
                    })}
                  >
                    {pd.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StatCompCallsByDirection;
