import { UserRoles } from "@/enums/user";
import { decodeJwt } from "jose";

/**
 * Tries to get the access token from the incoming request.
 *
 * > **_NOTE_**: For API routes only.
 *
 * @param req The incoming request.
 * @returns Either a potentially valid auth token, or `undefined`.
 */
export function tryGetToken(req: Request): string | undefined {
  const authHeader = req.headers.get("Authorization");
  if (!authHeader) {
    return undefined;
  }

  const [bearer, token] = authHeader.split(" ");
  if (bearer.toLowerCase() !== "bearer" || !token) {
    return undefined;
  }

  return token;
}

export const IsPrivileged = (accessToken: string): boolean => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.email &&
    typeof tokenPayload.email === "string" &&
    (tokenPayload.email.includes("@lula.com") ||
      tokenPayload.email.includes("@lula.is") ||
      tokenPayload.email.includes("@lularentals.com"))
  ) {
    return true;
  }

  return false;
};

export const GetAgencyId = (accessToken: string): string | null => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.agency_id &&
    typeof tokenPayload.agency_id === "string"
  ) {
    return tokenPayload.agency_id;
  }

  return null;
};

export const GetOrgId = (accessToken: string): string => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.org_id &&
    typeof tokenPayload.org_id === "string"
  ) {
    return tokenPayload.org_id;
  }

  return "";
};

export const GetOrgName = (accessToken: string): string => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.org_id &&
    tokenPayload.user_orgs &&
    Array.isArray(tokenPayload.user_orgs) &&
    typeof tokenPayload.org_id === "string"
  ) {
    const org = tokenPayload.user_orgs.find(
      (org: any) => org.id === tokenPayload.org_id
    );
    if (org && org.display_name) {
      return org.display_name;
    }
  }

  return "";
};

export const GetRole = (accessToken: string): UserRoles => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload["https://lula.com/roles"] &&
    (tokenPayload["https://lula.com/roles"] as any[]).length > 0
  ) {
    return (tokenPayload["https://lula.com/roles"] as any[])[0] as UserRoles;
  }

  return UserRoles.Admin;
};

export const GetEmail = (accessToken: string): string => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.email &&
    typeof tokenPayload.email === "string"
  ) {
    return tokenPayload.email;
  }

  return "";
};

export const GetUserId = (accessToken: string): string => {
  const tokenPayload = decodeJwt(accessToken);

  if (
    tokenPayload &&
    tokenPayload.sub &&
    typeof tokenPayload.sub === "string"
  ) {
    return tokenPayload.sub;
  }

  return "";
};

export const NavigateAwayForRole = (
  allowedRoles: UserRoles[],
  role: UserRoles,
  push: (url: string) => void
) => {
  if (!allowedRoles.includes(role)) {
    push("/unauthorized");
  }
};
