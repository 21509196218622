import { BarDataKeyedType, BarDataType } from "@/utils/common/charts";
import { ResponsiveBar } from "@nivo/bar";

type BarProps<
  T_index extends string = "any",
  T_values extends string = "any"
> = {
  data: (BarDataType | BarDataKeyedType<T_index, T_values>)[];
  keys?: string[];
  indexBy?: string;
  groupMode?: "grouped" | "stacked";
  label?: (d: any) => string;
  tooltip?: (d: any) => string | JSX.Element;
};

export const Bar = <
  T_index extends string = "any",
  T_values extends string = "any"
>({
  data,
  keys,
  indexBy,
  groupMode,
  label,
  tooltip,
}: BarProps<T_index, T_values>) => {
  const theme = window.localStorage.getItem("theme") || "light";
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      groupMode={groupMode}
      label={label}
      tooltip={tooltip}
      margin={{ top: 10, right: 0, bottom: 40, left: 50 }}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      colors={({
        id,
        data,
      }: {
        id: string | number;
        data: BarDataType | BarDataKeyedType<T_index, T_values>;
      }) => {
        if ("color" in data) {
          return data.color || "";
        }

        return `${id}Color` in data
          ? (data as any)[`${id}Color`]
          : "hsl(0, 0%, 0%)";
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: theme === "light" ? "#333" : "#fff",
            },
          },
        },
      }}
      labelTextColor={theme === "light" ? "#000" : "#fff"}
    />
  );
};
