"use client";

import clsx, { ClassValue } from "clsx";
import Link from "next/link";
import Icon, { ICON_SIZES } from "./Icon";
import { Tooltip } from "flowbite-react";

export type ButtonProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
  type?: "primary" | "secondary" | "tertiary" | "none";
  compact?: boolean;
  wide?: boolean;
  disabled?: boolean;
  restrictedText?: string;
  id?: string;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  secondaryAction?: () => void;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  testId?: string;
  className?: ClassValue;
  prefetch?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  href,
  onClick,
  type = "primary",
  className,
  id,
  testId: dataTestId,
  wide,
  compact,
  disabled,
  restrictedText,
  children,
  iconBefore,
  iconAfter,
  prefetch = true,
  target = "_self",
  secondaryAction,
}) => {
  if (
    !href &&
    !onClick &&
    process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
  ) {
    throw new Error("You must have an href or onClick prop");
  }

  if (href && target === "_blank" && !iconAfter) {
    iconAfter = (
      <Icon
        icon="arrow-up-right-from-square"
        size={compact ? ICON_SIZES.XXS : ICON_SIZES.SM}
      />
    );
  }

  if (restrictedText) {
    disabled = true;
  }

  const commonProps = {
    id,
    disabled,
    "data-testid": dataTestId,
    className: clsx(
      "rounded-md",
      {
        ["-skew-x-12 rounded-xl"]: type !== "none" && type !== "tertiary",
        ["py-2 px-4"]: !compact,
        ["py-1 px-2"]: compact,
        ["cursor-not-allowed"]: disabled,
        // Primary
        ["bg-button_primary border-2 border-button_primary hover:bg-button_primary/75"]:
          !disabled && type == "primary",
        ["bg-button_primary/25 border-2 border-button_primary/50 grayscale-75"]:
          disabled && type === "primary",
        // Secondary
        ["m-[2px] bg-button_secondary hover:bg-button_secondary/75"]:
          !disabled && type == "secondary",
        ["m-[2px] bg-button_secondary/50 grayscale-75"]:
          disabled && type == "secondary",
        // Tertiary
        ["m-[2px] bg-button_tertiary/25 border-2 border-button_tertiary hover:bg-button_tertiary/50 dark:border-gray-500 dark:bg-gray-700 dark:hover:bg-gray-600"]:
          !disabled && type == "tertiary",
        ["m-[2px] bg-white dark:bg-gray-800 border-2 border-button_tertiary dark:border-gray-700"]:
          disabled && type == "tertiary",
        // None
        ["m-[2px]"]: type == "none",
        // All
        ["w-full"]: wide,
        ["flex"]: !!href,
        ["inline-block"]: !!onClick,
      },
      className
    ),
  };

  const buttonChildren = (
    <div
      className={clsx("text-center", "m-auto", {
        ["skew-x-12"]: type !== "none" && type !== "tertiary",
        ["text-button_text_primary text-shadow shadow-gray-900/75"]:
          !disabled && type == "primary",
        ["text-button_text_primary text-shadow shadow-gray-900/25"]:
          disabled && type == "primary",
        ["text-button_text_secondary text-shadow-sm shadow-white-900/75"]:
          !disabled && type == "secondary",
        ["text-button_text_secondary/50 text-shadow-sm shadow-white-900/75"]:
          disabled && type == "secondary",
        ["text-sm"]: compact && type !== "tertiary",
        ["text-400"]: disabled && type == "tertiary",
        ["text-button_text_tertiary dark:text-gray-300 text-sm"]:
          !compact && type == "tertiary",
        ["text-button_text_tertiary dark:text-gray-300 text-xs"]:
          compact && type === "tertiary",
      })}
    >
      {iconBefore && (
        <span className={clsx("mr-2", "text-current")}>{iconBefore}</span>
      )}
      {children}
      {iconAfter && <span className="ml-2">{iconAfter}</span>}
    </div>
  );

  if (onClick) {
    const buttonComponent = (
      <button onClick={onClick} {...commonProps}>
        {buttonChildren}
      </button>
    );

    if (restrictedText) {
      return (
        <div className={clsx({ "[&>div:first-of-type]:w-full": wide })}>
          <Tooltip content={restrictedText}>{buttonComponent}</Tooltip>
        </div>
      );
    }

    return buttonComponent;
  }

  if (href) {
    const linkComponent = (
      <Link
        href={href}
        target={target}
        {...commonProps}
        prefetch={prefetch}
        onClick={secondaryAction ? () => secondaryAction() : undefined}
      >
        {buttonChildren}
      </Link>
    );

    if (restrictedText) {
      return (
        <div className={clsx({ "[&>div:first-of-type]:w-full": wide })}>
          <Tooltip content={restrictedText}>{linkComponent}</Tooltip>
        </div>
      );
    }

    return linkComponent;
  }
};

export default Button;
