"use client";

import "../../utils/common/charts";
import { useEffect, useState } from "react";
import Container from "@/components/common/container";
import { GetLoginLocation } from "@/utils/common/login";
import { useRouter } from "next/navigation";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import StatCompDailyCalls from "@/components/stats/StatCompDailyCalls";
import StatCompCallsByDirection from "@/components/stats/StatCompCallsByDirection";
import StatCompCallsByType from "@/components/stats/StatCompCallsByType";
import StatCompDirectionCard from "@/components/stats/StatCompDirectionCard";
import {
  CallByDayStat,
  CallsByTypeStat,
  StatsResponse,
  TotalCallStat,
} from "@/types/admin/dashboard";
import { apiLive } from "@/utils/common/api";
import {
  FormatManHours,
  FormatNumber,
  FormatSeconds,
} from "@/utils/common/generic";
import { useProfile } from "@/hooks/useProfile";

export default function Home() {
  const router = useRouter();
  const { user, getToken } = useAuthContext();
  const { profile } = useProfile();
  const { t } = useTranslation("stats");
  const { t: ct } = useTranslation("common");

  const [callsByDay, setCallsByDay] = useState<
    CallByDayStat[] | null | undefined
  >(undefined);
  const [totalCalls, setTotalCalls] = useState<
    TotalCallStat[] | null | undefined
  >(undefined);
  const [callsByType, setCallsByType] = useState<
    CallsByTypeStat[] | null | undefined
  >(undefined);

  useEffect(() => {
    const location = GetLoginLocation();

    if (location) {
      router.push(location);
      return;
    }
  }, [router, user]);

  const fetchCallsByDay = async () => {
    const statsResponse = await apiLive<StatsResponse<CallByDayStat[]>>(
      getToken,
      `/api/stats/450`
    );

    if (!statsResponse || !statsResponse.success) {
      setCallsByDay(null);
      return;
    }

    setCallsByDay(statsResponse.stats);
  };

  const fetchTotalCalls = async () => {
    const statsResponse = await apiLive<StatsResponse<TotalCallStat[]>>(
      getToken,
      `/api/stats/449`
    );

    if (!statsResponse || !statsResponse.success) {
      setTotalCalls(null);
      return;
    }

    setTotalCalls(statsResponse.stats);
  };

  const fetchCallsByType = async () => {
    const statsResponse = await apiLive<StatsResponse<CallsByTypeStat[]>>(
      getToken,
      `/api/stats/451`
    );

    if (!statsResponse || !statsResponse.success) {
      setCallsByType(null);
      return;
    }

    setCallsByType(statsResponse.stats);
  };

  useEffect(() => {
    document.title = `GAIL | ${t("dashboard")}`;

    fetchCallsByDay();
    fetchTotalCalls();
    fetchCallsByType();
  }, []);

  const getTotalCallStat = (direction: string, value: "count" | "duration") => {
    const data: number =
      totalCalls?.find((tc) => tc.direction === direction)?.[value] ?? -1;

    if (value === "count") {
      return FormatNumber(data, ct, 2, true);
    } else {
      return FormatManHours(data, profile.locale, ct, true);
    }
  };

  const getAverageDurationStat = (direction: string) => {
    const duration: number =
      totalCalls?.find((tc) => tc.direction === direction)?.duration ?? -1;
    const callCount: number =
      totalCalls?.find((tc) => tc.direction === direction)?.count ?? -1;

    if (duration > 0 && callCount > 0) {
      return FormatSeconds(duration / callCount, ct);
    }

    return ct("na");
  };

  const getBusiestDay = (direction: "inbound" | "outbound") => {
    const SevenDaysAgo = new Date();

    SevenDaysAgo.setDate(SevenDaysAgo.getDate() - 7);

    const busiestDay = callsByDay
      ?.filter(
        (cbd) =>
          cbd.direction === direction &&
          cbd.created_at > SevenDaysAgo.toISOString()
      )
      .sort((a, b) => b.count - a.count)[0];

    return busiestDay
      ? new Date(busiestDay.created_at.replace("Z", "")).toLocaleDateString(
          profile.locale,
          {
            weekday: "long",
          }
        )
      : "N/A";
  };

  return (
    <Container
      title={t("dashboard")}
      subTitle={
        !user
          ? t("dashboardSubtitleGeneric")
          : t("dashboardSubtitle", { name: user.name })
      }
    >
      <StatCompDailyCalls callsByDay={callsByDay} />
      <div className={clsx("grid", "gap-4", "grid-cols-1", "lg:grid-cols-2")}>
        <StatCompCallsByDirection isAdmin={false} totalCalls={totalCalls} />
        <StatCompCallsByType callsByType={callsByType} />
      </div>
      <div
        className={clsx(
          "mt-4",
          "grid",
          "gap-4",
          "grid-cols-1",
          "md:grid-cols-2",
          "lg:grid-cols-4"
        )}
      >
        <StatCompDirectionCard
          title={t("totalCalls")}
          stats={
            totalCalls
              ? {
                  inbound: getTotalCallStat("inbound", "count"),
                  outbound: getTotalCallStat("outbound", "count"),
                }
              : totalCalls
          }
        />
        <StatCompDirectionCard
          title={t("talkTime")}
          stats={
            totalCalls
              ? {
                  inbound: getTotalCallStat("inbound", "duration"),
                  outbound: getTotalCallStat("outbound", "duration"),
                }
              : totalCalls
          }
        />
        <StatCompDirectionCard
          title={t("averageDuration")}
          stats={
            totalCalls
              ? {
                  inbound: getAverageDurationStat("inbound"),
                  outbound: getAverageDurationStat("outbound"),
                }
              : totalCalls
          }
        />
        <StatCompDirectionCard
          title={t("busiestDayThisWeek")}
          stats={{
            inbound: getBusiestDay("inbound"),
            outbound: getBusiestDay("outbound"),
          }}
        />
        {/*<StatCompDirectionCard title={t("callsAfterHours")} stats={null} />*/}
      </div>
    </Container>
  );
}
