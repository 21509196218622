import { BRANDS } from "@/enums/brands";
import distinctColors from "distinct-colors";
import tinycolor from "tinycolor2";

let colorCache: string[] = [];
let colorCacheKey: string = "";
let backgroundColorCache: { primary: string; secondary: string } = {
  primary: "",
  secondary: "",
};
let backgroundColorCacheKey: string = "";

const COLOR_DIFFERENCE = 15;

export const GetColors = (
  count?: number,
  themeOverride?: "light" | "dark",
  brandOverride?: BRANDS,
  primaryOverride?: string,
  secondaryOverride?: string,
  primaryCompOverride?: string,
  secondaryCompOverride?: string
): string[] => {
  const theme =
    themeOverride ?? (window.localStorage.getItem("theme") || "light");
  const brand: BRANDS =
    brandOverride ??
    ((window.localStorage.getItem("brand") || BRANDS.GAIL) as BRANDS);

  if (count && count <= 4 && count > 0 && count !== 3) {
    //Use Primary and Secondary Colors and then a variation of those colors to fill out 4
    const newKey = `${theme}-${brand}`;

    if (colorCacheKey === newKey && !themeOverride && !brandOverride) {
      return colorCache.slice(0, count);
    }

    const htmlElement = document.querySelector("html");

    if (htmlElement) {
      const computedStyle = getComputedStyle(htmlElement);

      const gailColorPrimary =
        primaryOverride ??
        computedStyle.getPropertyValue("--gail-color-primary").trim();
      const gailColorPrimaryComp =
        primaryCompOverride ??
        computedStyle
          .getPropertyValue("--gail-color-primary-complement")
          .trim();
      const gailColorSecondary =
        secondaryOverride ??
        computedStyle.getPropertyValue("--gail-color-secondary").trim();
      const gailColorSecondaryComp =
        secondaryCompOverride ??
        computedStyle
          .getPropertyValue("--gail-color-secondary-complement")
          .trim();

      const primary = tinycolor(`rgb ${gailColorPrimary}`);
      const primaryComp = gailColorPrimaryComp
        ? tinycolor(`rgb ${gailColorPrimaryComp}`)
        : undefined;
      const secondary = tinycolor(`rgb ${gailColorSecondary}`);
      const secondaryComp = gailColorSecondaryComp
        ? tinycolor(`rgb ${gailColorSecondaryComp}`)
        : undefined;

      colorCache = [
        primary.toHexString(),
        secondary.toHexString(),
        primaryComp
          ? primaryComp.toHexString()
          : theme === "light"
          ? primary.isLight()
            ? primary.darken(COLOR_DIFFERENCE).toHexString()
            : primary.lighten(COLOR_DIFFERENCE).toHexString()
          : primary.isLight()
          ? primary.darken(COLOR_DIFFERENCE).toHexString()
          : primary.lighten(COLOR_DIFFERENCE).toHexString(),
        secondaryComp
          ? secondaryComp.toHexString()
          : theme === "light"
          ? secondary.isLight()
            ? secondary.darken(COLOR_DIFFERENCE).toHexString()
            : secondary.lighten(COLOR_DIFFERENCE).toHexString()
          : secondary.isLight()
          ? secondary.darken(COLOR_DIFFERENCE).toHexString()
          : secondary.lighten(COLOR_DIFFERENCE).toHexString(),
      ];
      colorCacheKey = newKey;

      return colorCache.slice(0, count);
    }
  }

  return count
    ? distinctColors({
        count,
        lightMin: theme === "light" ? 50 : 50,
        lightMax: theme === "light" ? 80 : 80,
      }).map((color) => color.hex())
    : [];
};

const AdjustToBackgroundColor = (
  color: tinycolor.Instance,
  theme: "light" | "dark"
): string => {
  if (theme === "light") {
    const adjustment = 80 - (color.getBrightness() / 255) * 100;

    return adjustment < 0
      ? color.toHexString()
      : color.brighten(adjustment).toHexString();
  } else {
    const adjustment = (color.getBrightness() / 255) * 100 - 20;

    return adjustment <= 0
      ? color.toHexString()
      : color.darken(adjustment).toHexString();
  }
};

export const GetBackgroundColors = (
  themeOverride?: "light" | "dark",
  brandOverride?: BRANDS,
  primaryOverride?: string,
  secondaryOverride?: string
): {
  primary: string;
  secondary: string;
} | null => {
  const theme: "light" | "dark" =
    themeOverride ??
    ((window.localStorage.getItem("theme") || "light") as "light" | "dark");
  const brand: BRANDS =
    brandOverride ??
    ((window.localStorage.getItem("brand") || BRANDS.GAIL) as BRANDS);

  //Use Primary and Secondary Colors and then a variation of those colors to fill out 4
  const newKey = `${theme}-${brand}`;

  if (backgroundColorCacheKey === newKey && !themeOverride && !brandOverride) {
    return backgroundColorCache;
  }

  const htmlElement = document.querySelector("html");

  if (htmlElement) {
    const computedStyle = getComputedStyle(htmlElement);

    const gailColorPrimary =
      primaryOverride ??
      computedStyle.getPropertyValue("--gail-color-primary").trim();
    const gailColorSecondary =
      secondaryOverride ??
      computedStyle.getPropertyValue("--gail-color-secondary").trim();

    const primary = tinycolor(`rgb ${gailColorPrimary}`);
    const secondary = tinycolor(`rgb ${gailColorSecondary}`);

    backgroundColorCache = {
      primary: AdjustToBackgroundColor(primary, theme),
      secondary: AdjustToBackgroundColor(secondary, theme),
    };
    backgroundColorCacheKey = newKey;

    return backgroundColorCache;
  }

  return null;
};
