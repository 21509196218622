import * as React from "react";
import { Popover } from "@headlessui/react";
import clsx from "clsx";
import { NayLayoutProps } from "./NavLayout";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { Icon, ICON_SIZES, ICON_TYPE } from "./Icon";
import { usePopper } from "react-popper";
import UserAvatar from "./UserAvatar";
import { GlobalNavigationItem } from "./NavLayoutItem";
import { User } from "@/contexts/common/AuthContextProvider";
import { GlobalNavSectionType } from "@/types/common/library";
import { getNameWithLastInitial } from "@/utils/library/formatters";
import { useTranslation } from "react-i18next";
import { IsAdmin } from "@/utils/common/admin";

type DefaultUserActionProps = {
  label: string;
  icon: IconName;
};

type UserActionProps = DefaultUserActionProps &
  (
    | {
        onClick: () => void;
        href?: never;
      }
    | {
        onClick?: never;
        href: string;
      }
  );

const UserAction: React.FC<UserActionProps> = ({
  label,
  onClick,
  href,
  icon,
}) => {
  return (
    <a
      onClick={onClick}
      href={href}
      className={clsx(
        "flex",
        "gap-2",
        "items-center",
        "justify-start",
        "w-full",
        "p-2",
        "transition-all",
        "cursor-pointer",
        "hover:bg-slate-100",
        "focus:bg-slate-100",
        "active:bg-slate-100",
        "dark:hover:bg-slate-800",
        "dark:focus:bg-slate-800",
        "dark:active:bg-slate-800",
        "rounded-lg"
      )}
    >
      <Icon icon={icon} size={ICON_SIZES.SM} type={ICON_TYPE.LIGHT} />
      <span className={clsx("text-sm", "mr-auto")}>{label}</span>
    </a>
  );
};

type Props = {
  user: User;
  bottomSection?: GlobalNavSectionType;
} & Pick<NayLayoutProps, "userActions">;

const NavLayoutSidebarFooter: React.FC<Props> = ({
  user,
  userActions,
  bottomSection,
}) => {
  const { t } = useTranslation("routes");
  const { t: lt } = useTranslation("login");

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  return (
    <div className={clsx("mt-auto")}>
      {bottomSection && (
        <div className={clsx("mb-4", "mx-[-1rem]")}>
          <label
            className={clsx(
              "pl-4",
              "uppercase",
              "text-600",
              "mt-6",
              "mb-2",
              "block"
            )}
          >
            {bottomSection.name}
          </label>
          <div className={clsx("grid", "self-stretch")}>
            {bottomSection.items.map((item) => (
              <GlobalNavigationItem
                key={`${item.label}_${item.icon}`}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
      <Popover>
        <Popover.Button
          ref={setReferenceElement}
          className={clsx(
            "flex",
            "items-center",
            "justify-between",
            "gap-4",
            "w-full",
            "block",
            "outline-none",
            "focus:ring-px",
            "transition-all",
            "active:bg-slate-200"
          )}
          onClick={() => setOpen(!open)}
        >
          <UserAvatar img={user?.img} name={user.name} />
          <span
            className={clsx(
              "mr-auto",
              "text-sm",
              "whitespace-nowrap",
              "overflow-hidden",
              "text-ellipsis"
            )}
          >
            {getNameWithLastInitial(user?.name || "")}
          </span>
          {open && (
            <span key="popperOpen">
              <Icon icon="caret-down" type={ICON_TYPE.SOLID} />
            </span>
          )}
          {!open && (
            <span key="popperClosed">
              <Icon icon="caret-up" type={ICON_TYPE.SOLID} />
            </span>
          )}
        </Popover.Button>
        <Popover.Panel
          className={clsx(
            "absolute",
            "z-30",
            "bg-gray-100",
            "dark:bg-gray-900",
            "md:bg-white",
            "rounded-lg"
          )}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className={clsx("p-4")}>
            <p className="text-sm">{user?.name}</p>
            <p className={clsx("text-xs", "mt-1", "text-slate-500")}>
              {user.email}
            </p>
          </div>
          <hr />

          {!!userActions && (
            <ul className={clsx("p-2", "space-y-2")}>
              {userActions?.onSettingsClick && (
                <UserAction
                  label={lt("settings")}
                  onClick={userActions.onSettingsClick}
                  icon="cog"
                />
              )}
              {userActions?.onUserManagementClick && (
                <UserAction
                  label={lt("userManagement")}
                  onClick={userActions.onUserManagementClick}
                  icon="user"
                />
              )}
              {IsAdmin(user) && (
                <UserAction
                  label={t("admin")}
                  href="/admin"
                  icon="user-crown"
                />
              )}
              {userActions?.onLogoutClick && (
                <UserAction
                  label={lt("logout")}
                  onClick={userActions?.onLogoutClick}
                  icon="sign-out-alt"
                />
              )}
            </ul>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export default NavLayoutSidebarFooter;
