import { MainAlertProps } from "@/types/common/alerts";

export const HandleCopy = (
  message: string,
  data: string,
  setAlert: (settings: MainAlertProps) => void
) => {
  navigator.clipboard.writeText(data).then(() => {
    setAlert({
      status: "success",
      message: message,
      secondsToClose: 4,
    });
  });
};

type HandlePasteType = {
  (
    key: string,
    message: string,
    setAlert: (settings: MainAlertProps) => void
  ): Promise<string>;
  (): Promise<string>;
};

export const HandlePaste: HandlePasteType = async (
  key?: string,
  message?: string,
  setAlert?: (settings: MainAlertProps) => void
) => {
  const data = await navigator.clipboard.readText();

  if (key && message && setAlert && !data.includes(key)) {
    setAlert({
      status: "error",
      message: message,
      secondsToClose: 10,
    });

    return "";
  }

  return data ?? "";
};

export const FormatNumber = (
  num: number,
  t: (val: string) => string,
  decimalPoints: number = 2,
  showNotAvailable: boolean = false
): string => {
  if (num < 0 && showNotAvailable) {
    return t("na");
  }

  if (!num || Number.isNaN(num)) {
    return "0";
  }

  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(decimalPoints) + t("billionInitials");
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(decimalPoints) + t("millionInitials");
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(decimalPoints) + t("thousandsInitials");
  } else {
    return num.toFixed(0);
  }
};

export const FormatTime = (seconds: number): string => {
  const days = seconds / 86400;

  if (days > 1) {
    return `${days.toFixed(2)} days`;
  }

  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return `${hours}:${minutes}:${secs}`;
};

export const FormatSeconds = (
  seconds: number,
  t: (val: string, data?: any) => string
): string => {
  let output: string[] = [];
  if (seconds > 60) {
    output.push(t("minutes", { count: Math.ceil(seconds / 60) }));
  }

  output.push(t("seconds", { count: Math.round(seconds % 60) }));

  return output.join(` ${t("and")} `);
};

const HOURS_PER_DAY = 8;
const DAYS_PER_WEEK = 5;
const SECONDS_PER_HOUR = 3600;

const WORK_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
const WORK_WEEK = WORK_DAY * DAYS_PER_WEEK;
const WORK_MONTH = WORK_WEEK * 4;
const WORK_YEAR = WORK_WEEK * 52;

export const FormatManHours = (
  seconds: number,
  locale: string,
  t: (val: string) => string,
  justHours: boolean = false
): string => {
  if (justHours) {
    return `${FormatNumber(Math.floor(seconds / 3600), t)} hours`;
  }

  const years = seconds / WORK_YEAR;

  if (years > 1) {
    return `${years.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} years`;
  }

  const months = seconds / WORK_MONTH;

  if (months > 1) {
    return `${months.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} months`;
  }

  const weeks = seconds / WORK_WEEK;

  if (weeks > 1) {
    return `${weeks.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} weeks`;
  }

  const days = seconds / WORK_DAY;

  if (days > 1) {
    return `${days.toFixed(2)} days`;
  }

  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return `${hours}:${minutes}:${secs}`;
};

export const GetDate_ISO = (referenceDate?: Date): string => {
  const today = referenceDate || new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const GetDayName = (
  t: (val: string) => string,
  referenceDate?: Date
): string => {
  const today = referenceDate || new Date();
  const day = today.getDay();

  return t(
    [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ][day]
  );
};

export const GetDayShortName = (
  t: (val: string) => string,
  referenceDate?: Date
): string => {
  const today = referenceDate || new Date();
  const day = today.getDay();

  return t(["sun", "mon", "tue", "wed", "thu", "fri", "sat"][day]);
};
