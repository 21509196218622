import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../library/Icon";

type StatProps = {
  title: string;
  stats:
    | {
        inbound: string;
        outbound: string;
      }
    | null
    | undefined;
};

const StatCompDirectionCard: React.FC<StatProps> = ({ title, stats }) => {
  const { t } = useTranslation("stats");
  const { t: cat } = useTranslation("calls");

  const LOADING_SKELETON = (
    <div
      className={clsx("animate-pulse", "bg-slate-500/30", "rounded", "min-h-3")}
    />
  );

  return (
    <div
      className={clsx(
        "p-4",
        "rounded-lg",
        "bg-panel_secondary",
        "border",
        "grid",
        "gap-2"
      )}
    >
      <div>
        <h2 className={clsx("mb-2")}>{title}</h2>
        <hr />
      </div>
      <div className={clsx("grid", "gap-2")}>
        {!stats ? (
          stats === null ? (
            <div>{t("noStatsAvailable")}</div>
          ) : (
            <>
              {LOADING_SKELETON}
              {LOADING_SKELETON}
              {LOADING_SKELETON}
              {LOADING_SKELETON}
            </>
          )
        ) : (
          <>
            <div>
              <div className="text-600 text-sm">{cat("inbound")}</div>
              <div>
                <Icon icon={"phone-arrow-down-left"} className="mr-2" />
                {stats.inbound}
              </div>
            </div>
            <div>
              <div className="text-600 text-sm">{cat("outbound")}</div>
              <div>
                <Icon icon={"phone-arrow-up-right"} className="mr-2" />
                {stats.outbound}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StatCompDirectionCard;
