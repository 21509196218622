import React from "react";
import { Fireworks } from "@fireworks-js/react";

const January: React.FC = () => {
  return (
    <Fireworks
      options={{ opacity: 0.5, particles: 25, intensity: 10 }}
      style={{
        width: "14rem",
        height: "14rem",
        margin: "auto",
      }}
    />
  );
};

export default January;
