import React from "react";
import Icon, { ICON_TYPE } from "../library/Icon";
import clsx from "clsx";
import "./february.css";

const February: React.FC = () => {
  return (
    <div
      className={clsx("mx-auto", "text-center", "mt-4", "relative", "max-w-32")}
    >
      <Icon
        icon="heart"
        size={7}
        type={ICON_TYPE.SOLID}
        className={clsx("animate-heartbeat", "text-red-600")}
      />
      <Icon
        icon="bow-arrow"
        size={2}
        className={clsx("animate-heartbeat", "absolute", "left-12", "top-10")}
      />
    </div>
  );
};

export default February;
