//register chartjs
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export type PieDataType = {
  active?: boolean;
  id: string;
  label: string;
  value: number;
  percent?: number;
  color?: string;
};

export type BarDataType = {
  id: string;
  value: number;
  label: string;
  color?: string;
};

export type BarDataKeyedType<
  T_index extends string,
  T_values extends string
> = {
  active?: boolean;
} & {
  [K in T_index]: string;
} & {
  [K in T_values]: number; // Ensure each food item has a number
} & {
  [K in `${T_values}Color`]: string; // Ensure each food item has a corresponding color
};
